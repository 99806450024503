/*
 * <div id="WIDGET_ID"></div>
 * <script>
 *   window.electioInit = window.electioInit || [];
 *   window.electioInit.push({
 *     token: "TOKEN",
 *   })
 * </script>
 *
 *
 *
 */

// Token should be domain specific
var TOKEN = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJBR0kiLCJpYXQiOjE1NTY2NjE2MDAsImV4cCI6MTYwOTQ1NTU5OSwiYXVkIjoicXVvdGlkaWFuby5uZXQiLCJzdWIiOiJyZWdpb25hbF9tYXBfdjIifQ.1LaGynu9V0RemQpCKgbVbYPXkgGZUGbhlK0FzRVqM58";

(function(w,d,s,l,o,t) {
  console.debug("Start Monrif Electio wrapper");
  w[l]=w[l]||[];
  if (w[l].length === 0) {
    console.debug("No Electio configured. Exit");
    return
  }
  var f=d.getElementsByTagName(s)[0],j=d.createElement(s);
  j.async=true;
  j.src=o+'/v2/common/js/main.js?t='+t;
  f.parentNode.insertBefore(j,f);
  j.onload = () => {
    console.debug("Init Electio", electioInit);
    MainElection.electionInit(o, w[l]);
  };
})(window,document,'script','electioInit','https://d1netop0t3ymeg.cloudfront.net',TOKEN);
